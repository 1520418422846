import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { ROW_GUTTER } from "./../../constants/ThemeConstant";
import { PrinterFilled } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatPhoneNumber } from "../../services/utils";

const checkboxDivStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};

const Page6 = (props) => {
  const theme = "light";
  const [form] = Form.useForm();
  const [displayPhoneValue, setDisplayPhoneValue] = useState("");
  const allValues = props.allValues;

  const generateReceipt = () => {
    const doc = new jsPDF();

    // Logo URL path relative to the public folder
    const logoUrl = `${window.location.origin}/assets/images/logo.png`;

    // Fetch the image as a Blob and then add it to the PDF
    fetch(logoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          // Add the image to the PDF (left-aligned)
          const marginLeft = 10; // Left margin for the logo
          doc.addImage(base64data, "PNG", marginLeft, 10, 50, 15);

          // Add the title "BigFanz Receipt" left-aligned
          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text("BigFanz Receipt", 10, 40);

          // Add more elements like tables, text, etc.
          doc.autoTable({
            startY: 50,
            head: [["Description", "Quantity", "Unit Price", "Total"]],
            body: [
              [
                allValues.planDetail.plan_name,
                "1",
                `$${allValues.totalAmount}`,
                `$${allValues.totalAmount}`,
              ],
            ],
            theme: "striped",
            styles: { fontSize: 11 },
            headStyles: { fillColor: [112, 48, 160] }, // Replaced green with #7030A0
            columnStyles: {
              0: { cellWidth: 85 },
              1: { cellWidth: 20 },
              2: { cellWidth: 40 },
              3: { cellWidth: 30 },
            },
          });

          // Add the Transaction Date below the table
          doc.setFontSize(12);
          doc.text(
            `Transaction Date: ${new Date().toLocaleString()}`,
            10,
            doc.lastAutoTable.finalY + 10
          );

          // Footer
          doc.text(
            "Thank you for your business!",
            105,
            doc.lastAutoTable.finalY + 50,
            { align: "center" }
          );

          // Save the PDF
          doc.save("bigfanz_receipt.pdf");
        };
      });
  };

  const handlePhoneChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    setDisplayPhoneValue(formatPhoneNumber(rawValue));
    form.setFieldsValue({ admin_phone_number: rawValue });
  };

  const onSubmit = (values) => {
    if (values.admin_password === values.admin_confirm_password)
      props.onSubmit(
        { ...values, admin_phone_number: displayPhoneValue.replace(/\D/g, "") },
        6
      );
  };

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <Form
        form={form}
        name="basicInformation"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onSubmit}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: 24,
            color: "#7030A0",
            fontFamily: "Poppins",
          }}
        >
          Payment Processed! Thank you
        </p>
        <Button
          icon={
            <PrinterFilled
              style={{ color: "#7030A0", fontSize: 18, marginTop: -10 }}
            />
          }
          style={{
            color: "#7030A0",
            size: 18,
            borderColor: "#7030A0",
            marginBottom: 10,
            fontFamily: "Poppins",
            fontWeight: "600",
          }}
          onClick={generateReceipt}
        >
          Print Receipt
        </Button>
        <Divider />
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Admin First Name"
              name="admin_first_name"
              rules={[
                {
                  required: true,
                  message: "Please input admin first name",
                },
              ]}
            >
              <Input maxLength={50} placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Admin Last Name"
              name="admin_last_name"
              rules={[
                {
                  required: true,
                  message: "Please input admin last name",
                },
              ]}
            >
              <Input maxLength={50} placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Admin Email Address"
              name="admin_email"
              rules={[
                {
                  required: true,
                  message: "Please input your email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input maxLength={100} placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Admin Phone Number"
              // name="admin_phone"
              rules={[
                {
                  required: true,
                  message: "Please input admin phone number",
                },
                {
                  validator: (_, value) => {
                    if (!value) return Promise.resolve();
                    const numericValue = value.replace(/\D/g, ""); // Extract only numbers
                    if (numericValue.length === 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please enter a valid 10-digit US phone number")
                    );
                  },
                },
              ]}
            >
              <Input
                value={displayPhoneValue}
                onChange={handlePhoneChange}
                placeholder="Enter your phone number"
                maxLength={14}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="User Name"
              name="admin_username"
              rules={[
                {
                  required: true,
                  message: "Please input admin username",
                },
              ]}
            >
              <Input maxLength={30} placeholder="Enter user name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Password"
              name="admin_password"
              rules={[
                {
                  required: true,
                  message: "Please input admin password",
                },
                {
                  min: 8,
                  max: 20,
                  message: "Password must be between 8 and 20 characters",
                },
                {
                  pattern: new RegExp("(?=.*[A-Z])"),
                  message:
                    "Password must include at least one uppercase letter",
                },
                {
                  pattern: new RegExp("(?=.*[0-9])"),
                  message: "Password must include at least one number",
                },
                {
                  pattern: new RegExp("(?=.*[!@#$%^&])"),
                  message:
                    "Password must include at least one special character from !@#$%^&",
                },
                {
                  pattern: new RegExp("^[^*]*$"),
                  message: "Password must not contain *",
                },
              ]}
            >
              <Input.Password maxLength={50} placeholder="Enter password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER} justify="start">
          <Col xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label="Confirm Password"
              name="admin_confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please input admin confirm password",
                },
              ]}
            >
              <Input.Password
                maxLength={50}
                placeholder="Enter confirm password"
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ ...checkboxDivStyle, marginTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ width: 150 }}
            // onClick={onS}
            loading={props.loading}
          >
            Submit
          </Button>
          {/* <Button
            type="text"
            style={{ color: "#7030A0", width: 150 }}
            onClick={props.onPrevious}
          >
            Back
          </Button> */}
          <a href="/">
            <Button type="text" style={{ color: "#7030A0" }}>
              Back to Home
            </Button>
          </a>
        </div>
      </Form>
    </div>
  );
};

export default Page6;
