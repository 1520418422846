import React, { useState } from "react";
import { Button, Steps, Modal } from "antd";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import Page6 from "./page6";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "./../../constants/ApiConstant";
import RegistrationService from "../../services/registration.services";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Registration = () => {
  const [current, setCurrent] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successRegId, setSuccessRegId] = useState(null);

  const [allValues, setAllValues] = useState({});

  const onPageSubmit = (values, pageNum) => {
    const newValues = { ...allValues, ...values };
    setAllValues(newValues);
    if (pageNum < 5) next();
    else if (pageNum === 5) {
      const data = {
        bus_name: newValues.businessName,
        referral_source: newValues.referralSource,
        referral_source_other: newValues.referral_source_other,
        goal_id_1: newValues.selectedGoals[0] || null,
        goal_id_2: newValues.selectedGoals[1] || null,
        goal_id_3: newValues.selectedGoals[2] || null,
        goal_other_flag: newValues.goalOtherSelected,
        goal_other: newValues.goalOtherText,
        locations_count: newValues.locationCount,
        locations_on_bigfanz: newValues.bigfanzLocationCount,
        industry_id: newValues.industryType,
        industry_other_flag: newValues.industryOtherFlag,
        contact_first_name: newValues.first_name,
        contact_last_name: newValues.last_name,
        contact_email: newValues.email,
        contact_phone: newValues.phone_number,
        website_url_1: newValues.website_url_1,
        reply_address_1: newValues.reply_address_1,
        reply_city: newValues.reply_city,
        reply_state: newValues.reply_state,
        reply_country: newValues.reply_country,
        plan_id_of_interest: newValues.selectedPlan,
        plan_unsure_flag: newValues.notSureFlag,
        pay_plan_of_interest: newValues.payPlanofInterest,
        auto_renew_flag: newValues.autoRenewFlag,
        // admin_first_name: newValues.admin_first_name,
        // admin_last_name: newValues.admin_last_name,
        // admin_email: newValues.admin_email,
        // admin_phone: newValues.admin_phone,
        // admin_username: newValues.admin_username,
        // admin_password: newValues.admin_password,
        stripeToken: newValues.stripeToken,
        totalAmount: Number(newValues.totalAmount),
        cc_type: newValues.cc_type,
      };
      setLoading(true);
      RegistrationService.addRegistration(data)
        .then((res) => {
          setSuccessRegId(res.reg_id);
          setCurrent(5);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert("Adding registration failed");
        });
    } else {
      const data = {
        admin_first_name: newValues.admin_first_name,
        admin_last_name: newValues.admin_last_name,
        admin_email: newValues.admin_email,
        admin_phone: newValues.admin_phone,
        admin_username: newValues.admin_username,
        admin_password: newValues.admin_password,
      };
      setLoading(true);
      RegistrationService.addAdminInRegsitration(successRegId, data)
        .then((res) => {
          setSuccessModal(true);
          setCurrent(0);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert("Adding Admin in Registration Failed");
        });
    }
  };

  const getModalWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 700) {
      return "95%";
    }
    return "70%";
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  // const handleSuccessModalCancel = () => {
  //   setSuccessModal(false);
  // };

  const steps = [
    {
      title: "Step 1",
      content: <Page1 allValues={allValues} onSubmit={onPageSubmit} />,
    },
    {
      title: "Step 2",
      content: (
        <Page2
          allValues={allValues}
          onSubmit={onPageSubmit}
          onPrevious={prev}
        />
      ),
    },
    {
      title: "Step 3",
      content: (
        <Page3
          allValues={allValues}
          onSubmit={onPageSubmit}
          onPrevious={prev}
        />
      ),
    },
    {
      title: "Step 4",
      content: (
        <Page4
          allValues={allValues}
          onSubmit={onPageSubmit}
          onPrevious={prev}
        />
      ),
    },
    {
      title: "Step 5",
      content: (
        <Page5
          allValues={allValues}
          planDetail={allValues.planDetail}
          onSubmit={onPageSubmit}
          onPrevious={prev}
          loading={loading}
        />
      ),
    },
    {
      title: "Step 6",
      content: (
        <Page6
          allValues={allValues}
          onSubmit={onPageSubmit}
          onPrevious={prev}
          loading={loading}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Elements
      options={{ appearance: { theme: "stripe" } }}
      stripe={stripePromise}
    >
      <div
        className="container d-flex flex-column justify-content-center h-100"
        style={{
          display: "flex",
          flex: 1,
          width: window.innerWidth,
          backgroundColor: "#fff",
          padding: "2% 5% 0px 5%",
        }}
      >
        <div>
          <a href="/" style={{ marginBottom: 20 }}>
            <img
              src="assets/images/logo.png"
              alt="Site Logo"
              // style={{ margin: 20 }}
            />
          </a>
          <Steps
            style={{ marginTop: 20, fontFamily: "Poppins", width: "65%" }}
            size="small"
            current={current}
            items={items}
          />
          <div style={{ paddingBottom: 20 }}>{steps[current].content}</div>
        </div>
        <Modal
          open={successModal}
          // onCancel={handleSuccessModalCancel}
          footer={null}
          width={getModalWidth()}
          style={{
            top: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="assets/images/celebrate.png"
              style={{ marginTop: -20, height: 200 }}
              alt="celebrate"
            />
            <img
              src="assets/images/logo.png"
              alt="Site Logo"
              style={{ height: 60 }}
            />
            <p
              style={{
                fontWeight: "600",
                fontSize: 34,
                color: "#7030A0",
                fontFamily: "Poppins",
                marginTop: 20,
                textAlign: "center",
              }}
            >
              Registration Complete!!!
            </p>
            <p
              style={{
                fontSize: 22,
                color: "#000000",
                textAlign: "center",
                padding: "0px 20px 0px 20px",
                fontFamily: "Poppins",
              }}
            >
              You will be sending you/your Admin email confirmation with
              instructions for completing your Bigfanz account setup. For
              security purposes your account must be confirmed at the email
              address provided. If for some reason you do not receive this
              email, please reach out to us at New account at 888-393-2293
            </p>
            <p
              style={{
                fontSize: 24,
                color: "#7030A0",
                textAlign: "center",
                padding: "0px 20px 0px 20px",
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              Thanks again for joining your Bigfanz community. we are excited to
              have you!
            </p>
            <a href="/">
              <Button
                type="primary"
                htmlType="submit"
                block
                style={{
                  width: 150,
                  fontFamily: "Poppins",
                }}
              >
                Back to Home
              </Button>
            </a>
          </div>
        </Modal>
      </div>
    </Elements>
  );
};
export default Registration;
