const hearAboutBigfanzOptions = [
  "Company Website",
  "Email Newsletter",
  "Social Media (Facebook, Instagram, Twitter, etc.)",
  "In-Store Promotion",
  "Word of Mouth (Friends/Family)",
  "Online Advertisement",
  "Search Engine (Google, Bing, etc.)",
  "SMS or Mobile Notification",
  "Loyalty Program App",
  "Customer Service Representative",
  "Other (Please specify)",
];

export { hearAboutBigfanzOptions };
