import React, { useState } from "react";
import { Button, Radio } from "antd";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./registration.css";
import StripePaymentForm from "../../components/stripePaymentForm";

const Page5 = (props) => {
  const [ccType, setccType] = useState("");
  const theme = "light";
  const stripe = useStripe();
  const elements = useElements();
  const { planDetail: plan, allValues } = props;

  const onSubmit = async (totalAmount) => {
    const cardElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (token)
      props.onSubmit(
        {
          stripeToken: token.id,
          totalAmount: totalAmount,
          cc_type: ccType,
        },
        5
      );
    if (error) {
      alert("Payment failed");
    }
  };

  const getAmount = (plan, allValues) => {
    const conversionRates = {
      year: { 1: 365, 7: 52, 30: 12, 365: 1 },
      month: { 1: 30, 7: 4, 30: 1, 365: 1 / 12 },
    };

    const frequency = allValues.payPlanofInterest.toLowerCase();
    return (
      plan.plan_cost * (conversionRates[frequency]?.[plan.bill_frequency] || 1)
    );
  };

  const amount = getAmount(plan, allValues);
  const tax = 0.0725 * amount;
  const totalAmount = amount + tax;

  return (
    <div
      style={{
        marginTop: "3%",
        width: window.innerWidth,
      }}
      className={`h-100 ${theme === "light" ? "bg-white" : ""}`}
    >
      <div
        style={{
          backgroundColor: "#FCF9FF",
          width: "40%",
          minWidth: 400,
          borderRadius: 10,
          padding: 10,
          marginBottom: 20,
        }}
      >
        <p
          style={{
            color: "#7030A0",
            fontSize: 20,
            fontWeight: "600",
          }}
        >
          Payment Summary
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 18,
          }}
        >
          <p style={{ color: "#242629" }}>Payment Selected</p>
          <p style={{ color: "#242629" }}>{allValues.payPlanofInterest}ly</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 18,
          }}
        >
          <p style={{ color: "#242629" }}>Amount</p>
          <p style={{ color: "#242629" }}>${amount.toFixed(2)}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 18,
          }}
        >
          <p style={{ color: "#242629" }}>Tax (7.25%)</p>
          <p style={{ color: "#242629" }}>+${tax.toFixed(2)}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 18,
          }}
        >
          <p style={{ color: "#242629" }}>Total Amount</p>
          <p style={{ color: "#7030A0", fontWeight: "700" }}>
            ${totalAmount.toFixed(2)}
          </p>
        </div>
      </div>
      <p style={{ fontSize: 20, color: "#7030A0", fontWeight: "600" }}>
        Payment method
      </p>
      <Radio.Group
        style={{ marginBottom: 20 }}
        value={ccType}
        onChange={(e) => setccType(e.target.value)}
      >
        <Radio value="visa">
          <img
            src="/assets/images/visalogo.png"
            style={{ width: 80, height: 70, marginRight: 10 }}
            alt="visalogo"
          />
        </Radio>
        <Radio value="mastercard">
          <img
            src="/assets/images/mastercardlogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="mastercardlogo"
          />
        </Radio>
        <Radio value="americanexpress">
          <img
            src="/assets/images/amexlogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="amexlogo"
          />
        </Radio>
        <Radio value="paypal">
          <img
            src="/assets/images/paypallogo.png"
            style={{ width: 60, height: 50, marginRight: 10 }}
            alt="paypallogo"
          />
        </Radio>
      </Radio.Group>
      <StripePaymentForm />
      <div className="flex-row-start" style={{ marginTop: 40 }}>
        <Button
          type="primary"
          block
          style={{ width: 150 }}
          onClick={() => onSubmit(totalAmount)}
          loading={props.loading}
        >
          Next
        </Button>
        <Button
          type="text"
          style={{ color: "#7030A0", width: 150 }}
          onClick={props.onPrevious}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Page5;
