import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import Home from "./pages/home";
import Registration from "./pages/registration";
import "./App.css";

const themes = {
  dark: `/css/dark-theme.css`,
  light: `/css/light-theme.css`,
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
]);

function App() {
  return (
    <div>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        // insertionPoint="styles-insertion-point"
      >
        <RouterProvider router={router} />
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
