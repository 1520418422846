const formatPhoneNumber = (value) => {
  if (value.length === 0) return "";
  const numericValue = value.replace(/\D/g, "");

  if (numericValue.length <= 3) {
    return `(${numericValue}`;
  } else if (numericValue.length <= 6) {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(
      3,
      6
    )}-${numericValue.slice(6, 10)}`;
  }
};

export { formatPhoneNumber };
